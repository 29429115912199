/*  Top Menu  */
nav.main-menu {
  background-color: #353526;
  height: 24px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 150;
}

nav.main-menu ul {
  margin:  -.25em 0 0 0;
}

nav.main-menu li {
  border-right: thin solid #898a77;
  display: inline;
  font-size: 0.75em;
  font-weight: 400;
  letter-spacing: .15em;
  padding: 0 2em;
  text-transform: uppercase;
}

@media only screen and (max-width: 1080px) {
  nav.main-menu li {
    font-size: 0.5em;
  }
}

nav.main-menu li:last-child, nav.bottom-menu li:last-child {
  border-right: none;
}

nav.main-menu li.selected a {
  color: #bc5b00;
}

/*  Bottom Menu  */
nav.bottom-menu {
  background-color: #68695A;
  height: 70px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  width: 100%;
  text-align: center;
}

nav.bottom-menu ul{
  margin: 0;
  padding: 0;
}
nav.bottom-menu li {
  border-right: thin solid #898a77;
  display: inline;
  font-size: 0.75em;
  padding: 0 2em;
  text-transform: uppercase;
}

nav.bottom-menu li.selected a {
  color: #D17316;
}

nav.bottom-menu ul.bottom-secondary li {
  font-size: .65em;
  text-transform: capitalize;
}
