/* ====================== */
/*        GENERAL         */
/* ====================== */
html {
  background-color: #353526;
}

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  /*color: #e4e0c7;*/
  color:  #E6E7D8;
  font-family: "itc-officina-sans-pro", sans-serif;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.6;
  margin: 0;  
}

@media screen and (max-width: 768px) {  /*for medium displays, less than or equal to 768em */
  body {
    font-size: 0.75em;
  }
}

@media screen and (max-width: 600px) {/*for small displays, less than or equal to 600px */
  body {
    font-size: 0.5em;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  line-height: 1.2;
}

p {
  margin: 0 0 0.8em;
}

ul, ol {
  margin: 0;
}

a {
  color: #E4E0C7;
  text-decoration: none;
  transition: color 150ms ease;
}

a:visited {
  color: shade(#E4E0C7, 25%);
}

a:hover {
  color: #D17316;
  text-decoration: underline;
}

hr {
  border-bottom: 1px solid #E4E0C7;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.6em 0;
}

img {
  margin: 0;
  max-width: 100%;
}

blockquote {
  font-style: italic;
}

button {
  appearance: none;
  background-color: #686959;
  border: 0;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-family: "itc-officina-sans-pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
  padding: 0.4em 0.6em;
  text-decoration: none;
  transition: background-color 150ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

button:hover, button:focus {
  background-color: shade(#686959, 20%);
  color: #D17316;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

button:disabled:hover {
  background-color: #E4E0C7;
}

/* for temporary menu pages */
ul.list-menu {
  list-style-type: none;
  padding: .5em 3%;
  margin-top: 1.25em;
}

ul.list-menu li span {
  color: #E4E0C7;
  font-size: 0.75em;
}

ul.list-menu .dim-some {
  color: #A3A390;
}

.asterisk {
  color: #D17316;
  font-size: 1.5em;
  font-weight: bold;
  vertical-align: middle;
}
/*   ===================   */
/*        HOME PAGE        */
/*   ===================   */
.home {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/home/home-bkgnd.jpg);
  background-size: cover;
  color: #dedfcf;
  height: 100vh;
  text-align: center;
 }

.home-wrapper {
  margin: 0 20%;
}

.home header {
  margin-bottom: 2em;
}

.home header h1.home-title {
  font-size: 3em;
  margin: 0;
  padding-top: 1.5em;
}

.home header p {
  font-size: .75em;
}

.home header a {
  color: #D17316;
  text-decoration: underline;
}

h2.city-stories {
  font-size: 1.5em;
  height: 100px;
  letter-spacing: 0.05em;
  margin: 0 auto 2em auto;
  text-transform: uppercase;
  width: 400px;
}

h2.city-stories a {
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid #686959;
  color: #D17316;
  display: block;
  padding: 0.5em;
  text-decoration: underline;
}

h2.city-stories  a:hover {
  background-color: rgba(178, 89, 0, 0.5);
}

.home footer {
  background-color: #68695A;
  margin: 1em 0 0 0;
  padding: .5em;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.tips {/* prototype viewing tips */
  font-size: .75em;
}

.tips a {
  color:  yellowgreen;
}
.neh-logo {
  max-width: 200px;
  opacity: 0.7;
}

/* ====================== */
/*      SHARED LAYOUT     */
/* ====================== */

header.basic-page, div.exhibit-pop-wrapper header {/* for menu pages, personal stories, essays, etc */
  background-color: #686959;
  font-size: 0.9em;
/*  height: 70px;*/
  padding: .5em 3%;
  position: relative;
  width: 100%;
}

header.basic-page nav p {
  color: #D17316;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: .05em;
  margin: 0;
}

header.basic-page nav p img {
  margin-right: .5em;
  width: 8%;
}

.basic-page h4 span.selected { /* used on Personal Stories Menu pages */
  color: #B25900;
}

header.basic-page p.blurb {
  color: #BDBEA7;
  font-size: 0.7em;
  left: 40%;
  line-height: 1.2em;
  padding: 1em;
  position: absolute;
  top:  5%;
  width: 45%;
}

header.basic-page div.page-title {
    background-color: rgba(53, 53, 38, 0.75);
    padding: 0.75em 3%;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    z-index: 105;
}

header.basic-page h1, header.basic-page h2, header.basic-page h3, header.basic-page h4, header.basic-page h5 {
  margin: 0;
}

header.basic-page h5 {
  color: #D17316;
}

.basic-grid {/* used in Personal Story pages, Theme pages */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 1.5em;
}

.first-col  {
  grid-column: 1/2;
  margin-left: 1.5em;
}

.second-col {
  grid-column: 2/5;
}

.second-row {
  grid-row: 2;
}

.third-row {
  grid-row: 3;
}

.fourth-row {
  grid-row: 4;
}

article.text-side {
  background: rgba(53, 53, 38, 0.8);
  grid-column: 5/7;
  grid-row-start:  1;
  grid-row-end: 5;
  padding: 1em;
  z-index: 92;
}

p.intro {
 font-size: 1.25em;
 line-height: 1.25em;
 margin-top: 3.5em;
}

.image-full {
  grid-column: 1/7;
  grid-row: 1;
}

.image-full img {
  width: 100%;
}

.sights-menu {
  position: relative;
}

.sights-menu article.intro-panel {
  left: 40%;
  position: absolute;
  top: 30%;
  width: 30%;
}

.image-full-caption {
  align-self: end;
  background: rgba(53, 53, 38, 0.8);
  grid-column: 1/3;
  grid-row: 1;
  margin: 0 0 1.5em 1.5em;
  z-index: 90;
}

ol.footnotes li {
  font-size: .8em;
  padding-bottom: .5em;
}

p.caption {
  color: #BDBEA7;
  border: 1px solid #686959;
  font-size: .75em;
  margin: 0;
  padding: .75em;
}

.narrative { /* for center column of text as in themes */
  margin: 1em auto 3em auto;
  max-width: 60%;
}

.narrative a {
  color: #B25900;
}

.narrative div p, div.full-image p {
  color: #e4e0c7;
  font-size: 0.75em;
  margin: .5em 0 2em 0;
}

.narrative div img {
  border: thin solid #686959;
  width: 90%;
}

img.fullpic {
  float: left;
  max-height: 60vh;
  margin-right: 1em;
}

.short-page {
  height: calc(100vh - 60px);
}

/*   ===================   */
/*         POP UPS         */
/*   ===================   */

/* For large pop ups -- required */
.lightbox {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(./assets/overlay.png) repeat;
  z-index: 99;
}

/* for City Story Grid */
/*.city-lightbox-wrapper {
  position: relative;
}
*/
.city-lightbox {
  background-color: rgba(53, 53, 38, 0.9);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}

.city-pop {
  padding: 2% 3%;
}

.bubble-zone {
  position: relative;
}


.city-pop header.bubbles {
  font-size: .9em;
  position: absolute;
  color: #E4E0C7;
  top: 10vh;
  left: 0;
  right: 0;
  width: 40%;
  padding: 1em;
  background-color: #686959;
  margin: auto
}

.city-pop header.bubbles h1 {
  margin: .5em 0;
}

.city-pop header.bubbles p a {
  color: #D17316;
}

.bubble-zone dl {
  clear: both;
  font-size: .75em;
  width: 25%;
}

.bubble-zone dl.bubble1 {
  position: absolute;
  top: 4vh;
  left: 0vw;
}

.bubble-zone dl.bubble2 {
  position: absolute;
  top: 22vh;
  left: 5vw;
}

.bubble-zone dl.bubble3 {
  position: absolute;
  top: 45vh;
  left: 3vw;
}

.bubble-zone dl.bubble4 {
  position: absolute;
  top: 62vh;
  left: 20vw;
}

.bubble-zone dl.bubble5 {
  position: absolute;
  top: 45vh;
  left: 40vw;
}

.bubble-zone dl.bubble6 {
  position: absolute;
  top: 60vh;
  left: 58vw;
}

.bubble-zone dl.bubble7 {
  position: absolute;
  top: 35vh;
  left: 70vw;
}

.bubble-zone dl.bubble8 {
  position: absolute;
  top: 15vh;
  left: 72vw;
}

.bubble-zone dl dt {
  float: left;
  margin-right: 5%;
}

.bubble-zone dl dt img {
  border-radius: 50%;
  height: 120px;
  width: 120px;
}

.bubble-zone dl.place dt img {
  border: 2px solid limegreen;
}

.bubble-zone dl.essay dt img {
  border: 2px solid orange;
}

.bubble-zone dl.people dt img {
  border: 2px solid #00BCD4;
}

.bubble-zone dl.visual dt img {
  border: 2px solid #9c27b0;
}

.bubble-zone dl.gallery dt img {
  border: 2px solid greenyellow;
}

.bubble-zone dl.timeline dt img {
  border: 2px solid firebrick;
}

.bubble-zone dl dd {
  line-height: 1.2em;
}

.bubble-zone dd.feature-title {
  padding-top: 5%;
  text-transform: uppercase;
}

.bubble-zone dl a:hover dt img {
  border: 2px dashed;
}

/* shared styles for all pop ups  */
.slimpop-wrapper {
  background-color: #E6E7D8;  
  border: 1px solid #BDBEA7;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.75);
  color: #353626;
  overflow:  auto;
  padding: 2em;
  position: relative;
}

.entry-pop {/* Large Pop Ups for Visual Record and Slide Shows */
  height: 80%;
  margin: 5% auto;
  text-align: left;
  width: 60%;
}

/* Like slimpop-wrapper for sub pop - Don */
.sub-pop-wrapper {
  /* background-color: #686959; */
  overflow:  auto;
  padding: 2em;
  position: relative;
}

/* Like entnry-pop for sub pop - Don */
.sub-pop {
  height: 85%;
  margin: 3% auto;
  text-align: left;
  width: 95%;
}

.sub-pop .theme, .sub-pop .basic-grid {
  background-color: rgba(53, 53, 38, 1);
}

.sub-pop header {
  height: auto;
}

.sub-pop .pop-nav {
  position: relative;
  z-index: 30;
}
.tips-pop {
  height: 75vh;
  margin: 5% auto;
  padding: 1.25em;
  width: 75%;
  overflow-y: scroll;
  font-size: .9em;
}

.tips-pop h3 {
  font-weight: bold;
}

.entry-pop header {
  max-width: 75%;
}

.entry-pop header h1 {
  font-size: 1.5em;
  font-style: normal;
  margin: 0;
}

.entry-pop header p {
  font-size: .8em;
  margin: .4em auto;
}

.entry-pop dl {
  margin-bottom: 0.8em;
}

.entry-pop dt {
  float: left;
  font-weight: bold;
  margin-right: 5px;
  margin-top: 0;
}

.entry-pop dd {
  margin-left: 10%;
}

.pop-nav {
  float: right;
  font-size: .7em;
  letter-spacing: .05em;
  max-width: 25%;
  margin: .8em;
}

.entry-pop a, .more-pop a {
  color: #B25900;
}

.pop-nav a:hover {
  text-decoration: underline;
}

.morepop-wrapper {/*  Small Pop Ups on Stroll, Timeline, etc. */
  padding: 1em;
}

.more-pop {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.75);
  max-height: 350px;
  overflow-y: scroll;
  position: absolute;
  width: 15%;
  z-index: 99;
}

.more-pop p.close {
  color: #B25900;
  font-size: .75em;
  float: right;
  letter-spacing: .05em;
  max-width: 10%;
}

.more-pop p.close a {
  color: #353626;
}

.more-pop header {
  max-width: 85%;
}

.more-pop header h1 {
  font-size: 1.5em;
  margin: 0;
}

.more-pop header p {
  font-size: .75em;
  line-height: 1.2em;
}

.exhibit-pop-wrapper {
  border: 1px solid #686959;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.75);
  color: #E6E7D8;
  font-size: .9em;
  overflow: auto;
  position: relative;
}

.exhibit-pop-wrapper header {
  background-color: rgba(53, 53, 38, 0.8) !important;
  z-index: 12;
  position: relative;
}

.exhibit-pop-wrapper h3 {
  font-size: .9em;
  color: #B25900;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.015em;
}

.exhibit-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.exhibit-pop-wrapper header div.slide-titles {
  margin-left: 3em;
  width: 80%;
  height: 80%;
}

.slide-titles h1, .slide-titles h3, .slide-titles p {
  margin: 0;
}
.exhibit-pop-wrapper header span {
  font-size: .8em;
  position: absolute;
  top: 2em;
  left: 10px;
}

.exhibit-pop-wrapper header a img {
  width: 2em;
  height: auto;
}
.exhibit-pop-wrapper header a.prev-arrow {
  position: absolute;
  top: 3em;
  left: 10px;
}

.exhibit-pop-wrapper header a.next-arrow {
  position: absolute;
  top: 3em;
  right: 10px;
}

.exhibit-pop-wrapper header a.close {
  font-size: .8em;
  position: absolute;
  top: 2em;
  right: 10px;
}
/*   ===================   */
/*     CITY STORY GRID     */
/*   ===================   */
div.city-story ul {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 16vh);
  grid-gap: 4px;
  list-style-type: none;
  font-size: .8em;
  padding: 3px;
}

div.city-story ul li {
  background-size: 20vw;
  line-height: 1em;
  outline: 1px solid #68695A;
  padding: 4px;
  position: relative;
}

div.city-story ul li.toprow {
  grid-row: 1;
}
div.city-story ul li.roots {
  grid-row: 2;
}
div.city-story ul li.lost {
  grid-row: 3;
}
div.city-story ul li.recon {
  grid-row: 4;
}
div.city-story ul li.legacy {
  grid-row: 5;
}
/*   city icons     */
.toprow img.city-icon {
  position: absolute;
  background-color: white;
  top: 4px;
  right: 4px;
  width: 30px;
  height: 30px;
}

/*   hover blurbs   */
div.city-story ul li span.show-blurb {
  width: 100%;
  background-color: rgba(256, 256, 256, 0.65);
  color: #353526;
  display: inline-block;
  font-size: 0.75em;
  font-style: normal;
  height: 100%;
  left: 0;
  line-height: 1.2em;
  padding: 4.25em 0.3em 4px 0.3em;
  position: absolute;
  top: 0;
  visibility: hidden;
  z-index: 1;
}

div.city-story ul li.intro span.story-title, div.city-story ul li.toprow span.story-title {
  text-transform: uppercase;
  display: block;
}

div.city-story ul li:hover span.show-blurb, div.city-story ul li:hover span.story-title {
  visibility: visible;
}

div.city-story ul li span.show-blurb a {
  color: #B25900;
}

div.city-story ul li:hover span.story-title, div.city-story ul li:hover span.story-subtitle {
  position: relative;
  z-index: 10;
  color: #353526;
}

div.city-story ul li:hover span.story-title a {
  color: #B25900;
}
/* background images */

div.city-story ul li {
  background-position-y: center;
}

div.city-story ul li.roots.nyc {
  background-image: url("http://dev.picturingurbanrenewal.org/prod-assets/city-stories/stuytown-roots.jpg");
} 
div.city-story ul li.lost.nyc {
  background-image: url("http://dev.picturingurbanrenewal.org/prod-assets/city-stories/stuytown-lost.jpg");
} 
div.city-story ul li.recon.nyc {
  background-image: url("http://dev.picturingurbanrenewal.org/prod-assets/city-stories/stuytown-recon.jpg");
} 
div.city-story ul li.legacy.nyc {
  background-image: url("http://dev.picturingurbanrenewal.org/prod-assets/city-stories/stuytown-legacy.jpg");
} 
div.city-story ul li.roots.newburgh {
  background-image: url("http://dev.picturingurbanrenewal.org/prod-assets/city-stories/newburgh-roots.jpg");
} 
div.city-story ul li.lost.newburgh {
  background-image: url("http://dev.picturingurbanrenewal.org/prod-assets/city-stories/newburgh-lost.jpg");
} 
div.city-story ul li.recon.newburgh {
  background-image: url("http://dev.picturingurbanrenewal.org/prod-assets/city-stories/newburgh-recon.jpg");
} 
div.city-story ul li.legacy.newburgh {
  background-image: url("http://dev.picturingurbanrenewal.org/prod-assets/city-stories/newburgh-legacy.jpg");
}
div.city-story ul li.roots.kingston {
  background-image: url("http://dev.picturingurbanrenewal.org/prod-assets/city-stories/kingston-roots.jpg");
} 
div.city-story ul li.lost.kingston {
  background-image: url("http://dev.picturingurbanrenewal.org/prod-assets/city-stories/kingston-lost.jpg");
} 
div.city-story ul li.recon.kingston {
  background-image: url("http://dev.picturingurbanrenewal.org/prod-assets/city-stories/kingston-recon.jpg");
} 
div.city-story ul li.legacy.kingston {
  background-image: url("http://dev.picturingurbanrenewal.org/prod-assets/city-stories/kingston-legacy.jpg");
}
div.city-story ul li.roots.albany {
  background-image: url("http://dev.picturingurbanrenewal.org/prod-assets/city-stories/albany-roots.jpg");
} 
div.city-story ul li.lost.albany {
  background-image: url("http://dev.picturingurbanrenewal.org/prod-assets/city-stories/albany-lost.jpg");
} 
div.city-story ul li.recon.albany {
  background-image: url("http://dev.picturingurbanrenewal.org/prod-assets/city-stories/albany-recon.jpg");
} 
div.city-story ul li.legacy.albany {
  background-image: url("http://dev.picturingurbanrenewal.org/prod-assets/city-stories/albany-legacy.jpg");
} 

/*   ===================   */
/*      VISUAL RECORD      */
/*   ===================   */
.images-menu {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 2em 0;
}

.images-menu-item {
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
}

.menu-image {
  width: 100%;
  display: block;
}

.menu-title {
  color: #BDBEA7;
  line-height: 1.2em;
  padding: 0 .25em;
  width: 100%;
/*  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;*/
}

.menu-title:hover {
    color: #D17316;
}

h4.visuals-count {
  font-size: 1em;
  font-weight: 400;
  margin: 2% 0 0 3%;
  text-transform: uppercase;
}

.filters, .images-menu {
  padding: 0.5em 3%;
  font-size: .75em;
}

.filters {
  display:  grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
}

.filter-set {
  list-style-type: none;
  padding-left: 0;
}

.filters div h4 {
  font-size: 1em;
  font-weight: 400;
  letter-spacing: .15em;
  margin: 0;
  text-transform: uppercase;
}

.filter-set .button {/* reset buttons */
  color: #E6E7D8;
}

.filter-set .button:hover, button:focus {
  background-color: shade(#686959, 20%);
  color: #D17316;
}

/* ========================== */
/*       THEMES & ESSAYS      */
/* ========================== */

nav.horiz-menu {/* menu page  */
  overflow-x: scroll;
}

.horiz-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.horiz-menu ul li {
  font-size: .85em;
  display: inline-block;
  height: 100vh;
  padding: 1em;
  vertical-align: top;
  white-space: normal;
  width: 30vw;
}

.horiz-menu ul li a {
  display: block;
  font-size: 1.25em;
  font-weight: normal;
  letter-spacing: 0.05em;
  line-height: 1.2em;
  text-transform: uppercase;
}

.contract-sales {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/themes/menupics/contract-sales.jpg);
}

.who-lived-here {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/themes/menupics/who-lived-here.jpg);
}

.anti-urban {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/themes/menupics/anti-urban.jpg);
}

.root-shock {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/themes/menupics/root-shock.jpg);
}

.ghost-city {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/themes/menupics/ghost-city.jpg);
}

.blight {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/themes/menupics/what-is-blight.jpg);
}

.contract-sales, .who-lived-here, .anti-urban, .root-shock, .ghost-city, .blight {
  background-repeat: no-repeat;
  background-size: cover;
}

.theme { /* themes pages  */
  background-color: rgba(53, 53, 38, 0.5);
  margin-top: -12em;
  padding-top: 0.5em;
  position: relative;
  z-index: 100;
}

.theme p.caption {
  border: none;
  float: left;
  padding: 0 1em 1em 0;
  width: 20%;
}

.theme p.first {
  color: #E6E7D8;
  padding: 1em .75em;
}

.bibliography {
  font-size: .75em;
}
    

/* ========================== */
/*      PERSONAL STORIES      */
/* ========================== */
.ps-full-menu {
  padding: 0 3% 5% 3%;
}

.ps-menu {
  grid-column: span 2;
}

.ps-city-menu {
  grid-column: span 3;
}

.ps-menu h2, .ps-city-menu h2 {
  font-size: 1.25em;
  font-weight: normal;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.ps-city-menu h2 {
  padding-left: 40px;
}

.ps-city-menu h2.stuytown-icon {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/common/icon-stuytown.svg) no-repeat;
}

.ps-city-menu h2.newburgh-icon {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/common/icon-newburgh.svg) no-repeat;
}

.ps-city-menu h2.kingston-icon {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/common/icon-kingston.svg) no-repeat;
}

.ps-city-menu h2.albany-icon {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/common/icon-albany.svg) no-repeat;
}

.ps-menu ul, .ps-city-menu ul {
  font-size: 0.75em;
  list-style-type: none;
  padding: 0.5em;
}

.ps-city-menu ul li {
  line-height: 1.2em;
  padding: 0.5em 0;
}

.ps-menu ul li {
  line-height: 1.2em;
  padding: 0.5em 30px;
}

.politicians ul {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/people/menupics/politicians.jpg);
}

.displaced ul {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/people/menupics/displaced.jpg);
}

.organizers ul {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/people/menupics/organizers.jpg);
}

.builders ul {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/people/menupics/builders.jpg);
}

.arrivals ul {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/people/menupics/politicians.jpg);
}

.reporters ul {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/people/menupics/reporters.jpg);
}
/*only used by people menu - decouple from horiz-menu 
.horiz-menu ul li.city-stuytown, .horiz-menu ul li.city-albany, .horiz-menu ul li.city-kingston, .horiz-menu ul li.city-newburgh {
  width: 25vw;
}*/
/*only used by people menu - decouple from horiz-menu 
.horiz-menu ul li ul.ps-city-menu li {
  display: block;
  height: auto;
  letter-spacing: normal;
  line-height: 1.2em;
  padding: 0 1em 0.5em 0;
  text-transform: none;
}*/

.city-albany ul {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/people/menupics/albany.jpg);
}

.city-stuytown ul {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/people/menupics/stuytown.jpg);
}

.city-kingston ul {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/people/menupics/kingston.jpg);
}

.city-newburgh ul {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/people/menupics/newburgh.jpg);
}

.icon-kingston {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/common/icon-kingston.svg) no-repeat;
  background-position: left;
  background-size: 24px 24px; }

.icon-albany {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/common/icon-albany.svg) no-repeat;
  background-position: left;
  background-size: 24px 24px; }

.icon-stuytown {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/common/icon-stuytown.svg) no-repeat;
  background-position: left;
  background-size: 24px 24px; }

.icon-newburgh {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/common/icon-newburgh.svg) no-repeat;
  background-position: left;
  background-size: 24px 24px; }

audio.personal-story-quote {
  margin-top:  1.5em;
}
/*.city-newburgh, .city-kingston, .city-stuytown, .city-albany {
  font-size: 1.25em;
  font-weight: normal;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  background-repeat: no-repeat;
  overflow: auto;
}*/

/* ====================== */
/*        TIMELINE        */
/* ====================== */
.timeline-top {
  background-color: #353526;
  height: 150px; /* not sure I need this */
  position: fixed;
  width: 100vw;
  z-index: 99;
}

.timeline-content {
  padding: 150px 0 4em 0;
  position: relative;
  z-index: 90;
}

.thru-lines {
  font-size: .85em;
  padding: 1em 0;
}

@media screen and (max-width: 1070px) {  /*for medium displays, less than or equal to 1070px */
  .thru-lines {
    font-size: 0.65em;
  }
}

.thru-lines ul li {
  display: inline-block;
  padding-right: 1em;
}

.thru-lines ul li.title {
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.thru-lines ul li button {
  background-color: #353526;
  border: 1px solid #E4E0C7;
  color: #E4E0C7;
}

.thru-lines ul li button.urban:hover {
  background-color: #353526;
  border: 1px solid #fb5e5e;
  color:  #fb5e5e;
}

.thru-lines ul li button.urban-selected {
  background-color: #fb5e5e;
  border: 1px solid #fb5e5e;
  color: #353526;
}

button.demo:hover {
  background-color: #353526;
  border: 1px solid #a8fb5e;
  color:  #a8fb5e;
}

.thru-lines ul li button.demo-selected {
  background-color: #a8fb5e;
  border: 1px solid #a8fb5e;
  color: #353526;
}

button.housing:hover {
  background-color: #353526;
  border: 1px solid #ffea39;
  color: #ffea39;
}

.thru-lines ul li button.housing-selected {
  background-color: #ffea39;
  border: 1px solid #ffea39;
  color: #353526;
}

button.recon:hover {
  background-color: #353526;
  border: 1px solid #39f3ff;
  color: #39f3ff;
}

.thru-lines ul li button.recon-selected {
  background-color: #39f3ff;
  border: 1px solid #39f3ff;
  color: #353526;
}

button.preserv:hover {
  background-color: #353526;
  border: 1px solid #fb88ff;
  color: #fb88ff;
}

.thru-lines ul li button.preserv-selected {
  background-color: #fb88ff;
  border: 1px solid #fb88ff;
  color: #353526;
}

button.protest:hover {
  background-color: #353526;
  border: 1px solid #ff9833;
  color: #ff9833;
}

.thru-lines ul li button.protest-selected {
  background-color: #ff9833;
  border: 1px solid #ff9833;
  color: #353526;
}

button.civil:hover {
  background-color: #353526;
  border: 1px solid #33ffb5;
  color: #33ffb5;
}

.thru-lines ul li button.civil-selected {
  background-color: #33ffb5;
  border: 1px solid #33ffb5;
  color: #353526;
}

button.public:hover {
  background-color: #353526;
  border: 1px solid #b05ff6;
  color: #b05ff6;
}

.thru-lines ul li button.public-selected {
  background-color: #b05ff6;
  border: 1px solid #b05ff6;
  color: #353526;
}

table {/* from css tricks https://css-tricks.com/making-tables-with-sticky-header-and-footers-got-a-bit-easier/ */
  margin: 0;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  width: fit-content;
  overflow-x: scroll;
}

table tr {
  vertical-align: top;
}

table td,
table th {
  border: 1px solid #686959;
  padding: 0.5rem 1rem;
  width: 150px;
}

table thead {
  position: sticky;
  top: 0;
  z-index: 99;
}

table thead th {
  background-color: #353526;
  color:  #686959;
  font-size: 2em;
  font-weight: normal;
  padding: 0 .25em;
}
  
tbody tr {
/*max-height: 80px;
  overflow: hidden;*/
}

table td {
  color: #E4E0C7;
  font-size: 0.65em;
  font-weight: normal;
  padding: 0;
  position: relative;
  text-align: left;
}

table tbody th {
  font-size: 0.85em;
  font-weight: normal;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: uppercase;
  position: relative;
}

table thead th:first-child {
  position: sticky;
  left: 0;
}

table tbody th {  /* first column - city names */
  background-color: #353526;
  left: 0;
  position: sticky;
  writing-mode: vertical-rl;
/*-ms-transform: rotate(270deg); /* IE 9
  transform: rotate(270deg);*/
  z-index: 1;
}

table tbody th.albany-icon {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/common/icon-albany.svg) no-repeat;
}

table tbody th.nyc-icon {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/common/icon-stuytown.svg) no-repeat;
}

table tbody th.kingston-icon {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/common/icon-kingston.svg) no-repeat;
}

table tbody th.newburgh-icon {
  background: url(http://dev.picturingurbanrenewal.org/prod-assets/common/icon-newburgh.svg) no-repeat;
}

table tbody th.albany-icon, table tbody th.nyc-icon, table tbody th.kingston-icon, table tbody th.newburgh-icon {
  background-size:  50px 50px;
  background-position: 10px 10px;
}

table tbody td {
  width: 150px;
}

table tbody td.dim {
  opacity: 0.25;
}

tr.row-nyc td {
  background-color: #394718;
}

tr.row-newburgh td {
  background-color: #1f2452;
}

tr.row-kingston td {
  background-color: #544213;
}

tr.row-albany td {
  background-color: #4d0e16;
}

.row-albany-1959 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/albany-1959.jpg);
  background-size: cover;
}

.row-albany-1961 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/albany-1961.jpg);
  background-size: cover;
}

.row-albany-1962 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/albany-1962.jpg);
  background-size: cover;
}

.row-albany-1963 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/albany-1963.jpg);
  background-size: cover;
}

.row-albany-1964 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/albany-1964.jpg);
  background-size: cover;
}

.row-albany-1965 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/albany-1965.jpg);
  background-size: cover;
}

.row-albany-1967 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/albany-1967.jpg);
  background-size: cover;
}

.row-albany-1968 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/albany-1968.jpg);
  background-size: cover;
}

.row-kingston-1959 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/kingston-1959.jpg);
  background-size: cover;
}

.row-kingston-1962 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/kingston-1962.jpg);
  background-size: cover;
}

.row-kingston-1963 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/kingston-1963.jpg);
  background-size: cover;
}

.row-kingston-1965 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/kingston-1965.jpg);
  background-size: cover;
}

.row-kingston-1966 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/kingston-1966.jpg);
  background-size: cover;
}

.row-kingston-1967 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/kingston-1967.jpg);
  background-size: cover;
}

.row-newburgh-1960 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/newburgh-1960.jpg);
  background-size: cover;
}

.row-newburgh-1961 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/newburgh-1961.jpg);
  background-size: cover;
}

.row-newburgh-1964 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/newburgh-1964.jpg);
  background-size: cover;
}

.row-newburgh-1967 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/newburgh-1967.jpg);
  background-size: cover;
}

.row-nyc-1957 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/nyc-1957.jpg);
  background-size: cover;
}

.row-nyc-1960 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/nyc-1960.jpg);
  background-size: cover;
}

.row-nyc-1963 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/nyc-1963.jpg);
  background-size: cover;
}

.row-nyc-1964 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/nyc-1964.jpg);
  background-size: cover;
}

.row-nystate-1959 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/nystate-1959.jpg);
  background-size: cover;
}

.row-nystate-1964 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/nystate-1964.jpg);
  background-size: cover;
}

.row-nystate-1968 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/nystate-1968.jpg);
  background-size: cover;
}

.row-us-1961 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/us-1961.jpg);
  background-size: cover;
}

.row-us-1963 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/us-1963.jpg);
  background-size: cover;
}

.row-us-1966 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/us-1966.jpg);
  background-size: cover;
}

.row-us-1968 {
  background-image: url(http://dev.picturingurbanrenewal.org/prod-assets/timeline/us-1968.jpg);
  background-size: cover;
}
td img.thruline {
  margin: 0;
  display: table-cell;
}

td span {
  display: block;
  line-height: 1.2em;
  padding: 4px;
}

.more-pop.timeline {/* More pops - Stroll version */
/*  margin-top: -65%;
    margin-left: 50%;*/
  left: 50vh;
  position: absolute;
  top: 30vh;
  width: 30vw;
}

/* ======================= */
/*     STY TOWN STROLL     */
/* ======================= */
.stroll-page {/*  to get bottom nav to the bottom of the page  */
  height: 100vh;
  perspective: 100;
  background-color: #000;
}

#stroll-wrapper {/* gashouse main layout elements  */
  height: calc(100vh - 100px);
  overflow-x: scroll;
  position: absolute;
  width: 100%;
}

.svg-content {
  height: calc(100% - 80px);
  width: auto;
}

.intro-photo {
}

#caption-band {
  font-size: .75em;
  height: 80px;
  margin-top: -10px;
  padding: .1em;
  position: relative;
  background-color: rgba(53, 53, 38, 0.8);
}

@media only screen and (max-width: 1000px) {
  #caption-band {
    font-size: 0.65em;
  }
}

/* intro page  */
div.intro-page {
  position: relative;
}

.intro-panel {
  background-color: rgba(53, 53, 38, 0.8);
  border: 1px solid #686959;
  font-size: .85em;
  left:  20%;
  padding: 1.5em;
  position: absolute;
  top:  10%;
  width:  60%;
}

.intro-panel a.start {
  color: #f09234;
  font-size: .75em;
  text-align: center;
}
.cap {/*caption titles*/
  position: absolute;
  width: 6%;
}

.cap h4, .cap p {
  margin: 0;
}

.turn-text {/*  turn corner buttons  */
  fill: #f09234;
  font-size: 1.5em;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-shadow: 1px 1px #333;
}

a:hover .turn-text {
  text-decoration: none;
}

.arrows {
  opacity: 0.8;
  fill: none;
  stroke: #f09234;
  stroke-width: 19;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.low-spot {/*  highlighting  */
  fill: #666666;
  opacity: 0;
}

.hi-spot {
  fill: #D17316;
  stroke-width: 2;
  stroke-miterlimit: 10;
  opacity: .25;
  stroke: #D17316;
  stroke-opacity: .9;
}

.hi-text {
  color: #D17316;
}

.more-pop.stroll {/* More pops - Stroll version */
  margin-top: -450px;
  margin-left: 5%;
}

/* ======================= */
/*     NEWBURGH SCROLL     */
/* ======================= */
.main-section {
  position: relative;
}

.image-panel {
  height: 80vh;
  position: relative;
}

.section-title {
  color: #D17316;
  font-size: 2em;
  font-weight: bold;
  left: 3%;
  position: fixed;
  text-shadow: 1px 1px black;
  top: 10%;
}

.image-panel-outer-div {
  position: fixed;
  top: 100;
  left: 10;
}

.chapter-title /* JJ cf: header.basic-page styles5 */ {
  background-color: #B25900;
  margin-top: 24px;
  padding: .5em 3%;
  text-align: center;
}

.chapter-title h1 {
  font-size: 1.75em;
  margin: 0;
}

.caption-panel {
  position: relative;
  z-index: 10;
  margin: 1em 1em 1em 50%;
  width: 100%;
}

.caption-panel div {
  background-color: rgba(53, 53, 38, 0.8);
  border: 1px solid #BDBEA7;
  margin-bottom: 90vh;
  padding: 1em;
  margin-top: -10em;
  width: 40%;
}

.caption-panel div.narrower {
  width: 30%;
}

.caption-panel div p.gallery-caption {
  width: 25%;
  font-size: 0.75em;
  float: left;
}

.caption-panel div p.gallery-quote {
  font-size: 1em;
  padding-left: 1em;
}

.caption-panel div p.gallery-text {
  font-size: 1em;
  font-style: normal;
}

.caption-panel div h4 {
  font-weight: bold;
}

a.caption-link {
  color: #B25900;
  text-decoration: underline;
}

#thing10 p.gallery-text {
  height: 10em;
}

div.city-story iframe {
  margin-top: -100px;
}

div.kingston-scroll iframe {
  margin-top: -22px;
  width: 1100px;
}


/* for "not yet implemented" tool tips -- */

nav.bottom-menu ul li a, ul.bottom-secondary li a, div.city-story-grid ul li a {
  position: relative;
}

.city-story-grid {
  position: relative;
}

.not-done-wrapper {
  position: relative;
}

span.not-done {
  width: 100%;
  background-color: white;
  border: 1px solid #D9D9D9;
  color: #333;
  font-size: 0.75em;
  line-height: 1.2em;
  font-style: normal;
  box-shadow: 4px 4px 6px 4px #333333a8;
  padding: 0.25em;
  text-transform: lowercase;
  width: 130px;

  z-index: 1; 
  visibility: hidden;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

a:hover span.not-done {
  visibility: visible;
}

/*   ===================   */
/*         COLORS          */
/*   ===================   */
/* #353526 dark background */
/* #686959 medium background */
/* #E6E7D8 light text */
/* #E4E0C7 shaded light text */
/* #353526 dark text */
/* #B25900 burnt orange background / links */
/* #D17316 orange link hover */
/* #394718 city: stuy town green */
/* #544213 city: kingston brown */
/* #1F2452 city: newburgh blue */
/* #FFA300 city: albany maroon */
